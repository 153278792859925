import React from 'react'
import Layout from '../../components/Default/Layout'
import PageHeader from '../../components/PageHeader/PageHeader'
import '../../components/ParagraphUnderPhoto/styles.css'
import Accordion from '../../components/Accordion/Accordion'
import AccordionTemplate from '../../components/Services/AccordionTemplate'
import Index from '../../../content/accordion/sluzby'
import { graphql } from 'gatsby'
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'
const Sluzby = ({ data: { page }, pageContext }) => {
  const { image, title } = page.frontmatter
  const {
    breadcrumb: { crumbs },
  } = pageContext
  return (
    <Layout
      meta={page.frontmatter.meta || false}
      title={page.frontmatter.title || false}
      metaCrumb={crumbs}
    >
      <PageHeader title={title} backgroundImage={image.childImageSharp.fluid} />
      <Breadcrumb crumbs={crumbs} crumbLabel={'Služby'} />
      <div className="Paragraph--Wrapper container">
        <p>
          Nechte si poradit od skutečných profesionálů v oboru. Pomůžeme Vám
          zorientovat se v daňové a účetní legislativě a ulevíme od účetní či
          mzdové agendy. Všechna zadání řešíme odborně, efektivně a s Vaší
          minimální časovou investicí. Nabízíme konzultace jednotlivých
          transakcí i dlouhodobou spolupráci.
        </p>
        <p>
          K dispozici Vám budou certifikovaní daňoví poradci a kvalifikovaní
          účetní s praxí. Každý člen týmu má navíc svou specializaci, ve které
          se aktivně rozvíjí, díky čemuž víte, že se o Vás stará skutečný
          odborník.
        </p>
      </div>
      <Accordion
        className="container"
        items={[
          {
            title: Index.dane.title,
            description: <AccordionTemplate {...Index.dane} />,
          },
          {
            title: Index.mzdy.title,
            description: <AccordionTemplate {...Index.mzdy} />,
          },
          {
            title: Index.zahranici.title,
            description: <AccordionTemplate {...Index.zahranici} />,
          },
          {
            title: Index.firemniPoradenstvi.title,
            description: <AccordionTemplate {...Index.firemniPoradenstvi} />,
          },
          {
            title: Index.ucetnictvi.title,
            description: <AccordionTemplate {...Index.ucetnictvi} />,
          },
          {
            title: Index.znaleckePosudky.title,
            description: <AccordionTemplate {...Index.znaleckePosudky} />,
          },
        ]}
      />
    </Layout>
  )
}

export default Sluzby

export const pageQuery = graphql`
  query Sluzby {
    page: markdownRemark(frontmatter: { slug: { eq: "sluzby" } }) {
      ...Meta
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
